<template>
  <section>
    <CContainer>
      <CForm @submit.stop.prevent="Save()">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol md="8" lg="9">
                <h5>{{ $t('Navigation.Offer/' + Action + 'Activity') }}</h5>
                <hr>
                <div class="mb-5">
                  <CInput v-model="Data.Name" :label="$t('Offer.ActivityName')" :placeholder="$t('Offer.ActivityName')" type="text" />
                  <CTextarea v-model="Data.Description" :label="$t('Offer.ActivityDescription')" :placeholder="$t('Offer.ActivityDescription')" rows="3" />
                  <h6>{{ $t('Post/Detail.Cover') }}</h6>
                  <CRow class="no-gutters mb-3 p-3 bg-light">
                    <CCol v-if="Data.Cover" xl="2" lg="3" md="4" sm="6">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.Cover" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('Cover', false)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol v-if="!Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </div>
                <h5>{{ $t('Offer.DiscountSetting') }}</h5>
                <hr>
                <div class="mb-5">
                  <CSelect :label="$t('Offer.Target')" :options="TargetOptions" v-model="Data.Offer.Target" :value.sync="Data.Offer.Target" :placeholder="$t('Global.PleaseSelect')" @change="ChangeOfferTarget" />
                  <CSelect :label="$t('Offer.DiscountType')" :options="ActivityTypeOptions" v-model="Data.Offer.DiscountType" :value.sync="Data.Offer.DiscountType" :placeholder="$t('Global.PleaseSelect')" @change="ChangeOfferTarget" />
                  <div v-if="(Data.Offer.Target === 'ProductAmount' || Data.Offer.Target === 'ProductQuantity' || Data.Offer.Target === 'ProductAmountExcludes' || Data.Offer.Target === 'ProductQuantityExcludes')" class="mb-3">
                    <div class="font-weight-bold d-flex justify-content-between mb-2">
                      <label>
                        {{ Data.Offer.Target === 'ProductAmountExcludes' || Data.Offer.Target === 'ProductQuantityExcludes' ? $t('Offer.SelectExcludesItem') : $t('Offer.SelectItem') }}
                        <CBadge v-if="Data.Offer.Target === 'ProductAmountExcludes' || Data.Offer.Target === 'ProductQuantityExcludes'" class="ml-1" color="info" v-c-tooltip="{content: $t('Global.PreviewDescription'), placement: 'bottom'}">{{ $t('Global.Preview') }}</CBadge>
                      </label>
                      <div>
                        <CButton class="mr-2" color="success" size="sm" variant="outline" v-c-tooltip="$t('Global.Add') + $t('Product.TaxonomyOptions.Default')" @click="OpenProductListModel('TargetItem')">
                          <CIcon name="cil-plus" class="c-icon-custom-size mr-1" />{{ $t('Global.Add') }}{{ $t('Product.TaxonomyOptions.Default') }}
                        </CButton>
                        <CButton color="info" size="sm" variant="outline" v-c-tooltip="$t('Global.Add') + $t('Product.TaxonomyOptions.ExtraItems')" @click="OpenProductListModel('TargetExtraItems')">
                          <CIcon name="cil-plus" class="c-icon-custom-size mr-1" />{{ $t('Global.Add') }}{{ $t('Product.TaxonomyOptions.ExtraItems') }}
                        </CButton>
                      </div>
                    </div>
                    <CDataTable
                      :loading="Loading"
                      id="SelectItemList"
                      :items="Data.Offer.TargetItem"
                      :fields="TargetItemField"
                      :noItemsView="NoItemsView"
                      responsive
                      hover
                    >
                      <template #Cover="{item}">
                        <td>
                          <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
                        </td>
                      </template>
                      <template #Action="{index}">
                        <td>
                          <CButton color="danger" size="sm" v-c-tooltip="$t('Global.Remove')" @click="RemoveTargetItems(index)">
                            <CIcon name="cil-trash" class="c-icon-custom-size" />
                          </CButton>
                        </td>
                      </template>
                    </CDataTable>
                  </div>
                  <div class="form-group">
                    <label class="d-block mb-3">
                      {{ $t('Offer.Condition') }}
                      <CBadge v-if="Data.Offer.DiscountType === 'SelectGift'" class="ml-1" color="info" v-c-tooltip="{content: $t('Global.PreviewDescription'), placement: 'bottom'}">{{ $t('Global.Preview') }}</CBadge>
                    </label>
                    <div v-for="(Item, ConditionIndex) in Data.Condition" :key="ConditionIndex" class="border mb-2">
                      <div class="row px-3 py-2">
                        <div v-if="(Data.Offer.Target !== 'NA')" class="col-5">
                          <CInput v-if="Data.Offer.Target !== 'ProductQuantity' && Data.Offer.Target !== 'OrderQuantity' && Data.Offer.Target !== 'ProductQuantityExcludes'" :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}" size="sm" v-model="Item.FullAmount" :label="$t('Offer.FullAmount')" class="mb-0" :placeholder="$t('Offer.FullAmountPlaceHolder')" type="number">
                            <template #prepend-content>$</template>
                            <template #description>
                              <small v-if="Validation[ConditionIndex.toString()]" class="text-danger form-text w-100">
                                {{ $t('Message.' + Validation[ConditionIndex.toString()]) }}
                              </small>
                            </template>
                          </CInput>
                          <CInput v-if="Data.Offer.Target === 'ProductQuantity' || Data.Offer.Target === 'OrderQuantity' || Data.Offer.Target === 'ProductQuantityExcludes'" :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}" size="sm" v-model="Item.Quantity" :label="$t('Offer.Quantity')" class="mb-0" :placeholder="$t('Offer.Quantity')" type="number">
                            <template #append-content>{{ $t('Offer.PCS') }}</template>
                            <template #description>
                              <small v-if="Validation[ConditionIndex.toString()]" class="text-danger form-text w-100">
                                {{ $t('Message.' + Validation[ConditionIndex.toString()]) }}
                              </small>
                            </template>
                          </CInput>
                        </div>
                        <div class="col-5">
                          <CInput :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}" size="sm" v-model="Item.Value" :label="$t('Offer.' + Data.Offer.DiscountType)" class="mb-0" :placeholder="$t('Offer.' + Data.Offer.DiscountType + 'Unit')" type="number">
                            <template #prepend-content>
                              <span v-if="Data.Offer.DiscountType === 'Price'">$</span>
                            </template>
                            <template #append-content>
                              <span v-if="Data.Offer.DiscountType === 'Percent'">%</span>
                              <span v-if="Data.Offer.DiscountType === 'Gift' || Data.Offer.DiscountType === 'SelectGift'">{{ $t('Offer.PCS') }}</span>
                            </template>
                          </CInput>
                        </div>
                        <div class="col-2 text-right">
                          <CButton v-if="Data.Offer.DiscountType === 'Gift'" color="success" size="sm" variant="ghost" v-c-tooltip="$t('Offer.ChooseGift')" @click="OpenProductListModel('Gift', ConditionIndex)">
                            <CIcon name="cil-gift" class="c-icon-custom-size" />
                          </CButton>
                          <CButton v-if="Data.Offer.DiscountType === 'SelectGift'" color="success" size="sm" variant="ghost" v-c-tooltip="$t('Offer.ChooseGift')" @click="OpenProductListModel('SelectGift', ConditionIndex)">
                            <CIcon name="cil-gift" class="c-icon-custom-size" />
                          </CButton>
                          <CButton v-if="(Data.Offer.Target !== 'NA')" color="danger" size="sm" variant="ghost" v-c-tooltip="$t('Global.Remove')" @click="RemoveCondition(ConditionIndex)">
                            <CIcon name="cil-trash" class="c-icon-custom-size" />
                          </CButton>
                        </div>
                        <div v-if="Data.Offer.DiscountType === 'Gift' && Item.ItemData && Item.ItemData.SeriesNum" class="col-12 mt-2">
                          <CMedia :asideImageProps="{src: Item.ItemData.Cover, width: '80px', height: '80px'}" class="bg-light text-dark p-3">
                            <h5 class="mt-0 mb-3">{{ $t('Offer.ChooseGiftInfo') }}</h5>
                            <p class="mb-0">
                              {{ $t('Offer.GiftName') }}: {{ Item.ItemData.Name }}<br>
                              {{ $t('Offer.GiftSeriesNum') }}: {{ Item.ItemData.SeriesNum }}
                            </p>
                          </CMedia>
                        </div>
                        <div v-if="Data.Offer.DiscountType === 'SelectGift'" class="col-12 mt-2">
                          <CDataTable
                            id="SelectGiftItemList"
                            :items="Item.ItemDataArray"
                            :fields="TargetItemField"
                            :noItemsView="NoItemsView"
                            responsive
                            hover
                          >
                            <template #Cover="{item}">
                              <td>
                                <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
                              </td>
                            </template>
                            <template #Action="{index}">
                              <td>
                                <CButton color="danger" size="sm" v-c-tooltip="$t('Global.Remove')" @click="RemoveConditionItemDataArray(ConditionIndex, index)">
                                  <CIcon name="cil-trash" class="c-icon-custom-size" />
                                </CButton>
                              </td>
                            </template>
                          </CDataTable>
                        </div>
                      </div>
                    </div>
                    <div v-if="(Data.Offer.Target !== 'NA')" class="text-left mt-3">
                      <CButton color="info" size="sm" variant="outline" @click="AddCondition()">
                        <CIcon name="cil-plus" class="c-icon-custom-size"/> {{ $t('Global.Add') + $t('Offer.Condition') }}
                      </CButton>
                    </div>
                  </div>
                  <div class="form-group">
                  </div>
                </div>
                <h5>{{ $t('Offer.OfferLimit') }}</h5>
                <hr>
                <div class="mb-4">
                  <div class="form-group">
                    <label class="d-block mb-3">{{ $t('Offer.UsagePage') }}</label>
                    <multiselect
                      v-model="Data.UsagePage"
                      :tag-placeholder="$t('Global.EnterToAdd')"
                      :placeholder="$t('Global.PleaseKeyIn')"
                      :deselectLabel="$t('Global.DeselectLabel')"
                      :selectedLabel="$t('Global.SelectedLabel')"
                      :options="PageList"
                      :optionHeight="34"
                      label="Name"
                      track-by="Slug"
                      :multiple="true"
                      @select="CheckSelectUsagePage"
                    >
                      <template #noOptions>
                        {{ $t('Global.NoOptions') }}
                      </template>
                    </multiselect>
                  </div>
                  <CSelect :label="$t('Offer.TargetGroup')" :options="TargetGroupOptions" v-model="Data.TargetGroup.Type" :value.sync="Data.TargetGroup.Type" :placeholder="$t('Global.PleaseSelect')" @update:value="Data.TargetGroup.List = []" />
                  <div v-if="(Data.TargetGroup.Type === 'MemberTag' || Data.TargetGroup.Type === 'MemberLevel')" class="form-group">
                    <label class="d-block mb-3">{{ $t('Offer.SelectTargetGroup') }}</label>
                    <multiselect
                      v-if="(Data.TargetGroup.Type === 'MemberTag')"
                      v-model="Data.TargetGroup.List"
                      :tag-placeholder="$t('Global.EnterToAdd')"
                      :placeholder="$t('Global.PleaseKeyIn')"
                      :deselectLabel="$t('Global.DeselectLabel')"
                      :selectedLabel="$t('Global.SelectedLabel')"
                      :options="Data.TargetGroup.List"
                      :multiple="true"
                      :taggable="true"
                      @tag="(item) => Data.TargetGroup.List.push(item)"
                    >
                      <template #noOptions>
                        {{ $t('Global.NoOptions') }}
                      </template>
                    </multiselect>
                    <multiselect
                      v-if="(Data.TargetGroup.Type === 'MemberLevel')"
                      v-model="Data.TargetGroup.List"
                      label="Name"
                      track-by="ID"
                      :tag-placeholder="$t('Global.EnterToAdd')"
                      :placeholder="$t('Global.PleaseKeyIn')"
                      :deselectLabel="$t('Global.DeselectLabel')"
                      :selectedLabel="$t('Global.SelectedLabel')"
                      :options="MemberLevelList"
                      :multiple="true"
                      :taggable="true"
                    >
                      <template #noOptions>
                        {{ $t('Global.NoOptions') }}
                      </template>
                    </multiselect>
                  </div>
                </div>
              </CCol>
              <CCol md="4" lg="3">
                <div class="form-group">
                  <label class="d-block">{{ $t('Offer.StartTime') }}</label>
                  <v-date-picker v-model="Data.StartTime" mode="dateTime" is24hr>
                    <template v-slot="{ inputValue, inputEvents }">
                      <CInput placeholder="YYYY-MM-DD HH:mm:ss" :value="inputValue" v-on="inputEvents">
                        <template #append-content>
                          <CIcon name="cil-calendar"/>
                        </template>
                      </CInput>
                    </template>
                  </v-date-picker>
                </div>
                <div class="form-group">
                  <label class="d-block">{{ $t('Offer.EndTime') }}</label>
                  <v-date-picker v-model="Data.EndTime" mode="dateTime" is24hr>
                    <template v-slot="{ inputValue, inputEvents }">
                      <CInput placeholder="YYYY-MM-DD HH:mm:ss" :value="inputValue" v-on="inputEvents">
                        <template #append-content>
                          <CIcon name="cil-calendar"/>
                        </template>
                      </CInput>
                    </template>
                  </v-date-picker>
                </div>
<!--                <CInput v-model="Data.Variable.AvailableUsage" :placeholder="$t('Offer.CouponVariable.AvailableUsageDescription')" type="number">-->
<!--                  <template #label>-->
<!--                    <label class="mr-1">{{ $t('Offer.CouponVariable.AvailableUsage') }}</label>-->
<!--                    <i v-c-tooltip="{content: $t('Offer.CouponVariable.AvailableUsageDescription'),placement: 'right'}" class="fas fa-question-circle text-info" />-->
<!--                  </template>-->
<!--                </CInput>-->
                <CInput v-model="Data.Variable.AvailableUserRepeatUsage" :placeholder="$t('Offer.CouponVariable.AvailableUserRepeatUsageDescription')" type="number">
                  <template #label>
                    <label class="mr-1">{{ $t('Offer.CouponVariable.AvailableUserRepeatUsage') }}</label>
                    <i v-c-tooltip="{content: $t('Offer.CouponVariable.AvailableUserRepeatUsageDescription'),placement: 'right'}" class="fas fa-question-circle text-info" />
                  </template>
                </CInput>
                <div class="form-group">
                  <label class="d-block">{{ $t('Offer.Status') }}</label>
                  <CSwitch color="success" :checked.sync="StatusSwitch"/>
                </div>
                <div class="form-group">
                  <label class="d-block">{{ $t('Offer.FreeLogisticsFee') }}</label>
                  <CSwitch color="success" :checked.sync="Data.Offer.FreeLogisticsFee"/>
                </div>
                <div class="form-group">
                  <label class="d-block">{{ $t('Offer.FreeServiceFee') }}</label>
                  <CSwitch color="success" :checked.sync="Data.Offer.FreeServiceFee"/>
                </div>
<!--                <div class="form-group">-->
<!--                  <label class="d-block">{{ $t('Offer.CouponVariable.AvailableDuplicateOtherOffer') }}</label>-->
<!--                  <CSwitch color="success" :checked.sync="Data.Variable.AvailableDuplicateOtherOffer"/>-->
<!--                </div>-->
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter class="text-right">
            <CButton color="success" type="submit">
              {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
            </CButton>
          </CCardFooter>
          <CElementCover v-if="(Loading === true)" :opacity="0.75">
            <CSpinner color="success"/>
          </CElementCover>
        </CCard>
      </CForm>
    </CContainer>
    <MediaStore :Config="MediaStoreConfig" ref="MediaStore" @OK="SetImages(ChooseImageType)"></MediaStore>
    <MediaMeta :Config="MediaMetaConfig" @OK="SetMeta"></MediaMeta>
    <component :is="ProductListModelComponent" :Single="Single" :Toggle="ProductListModel" :ChooseProductType="ChooseProductType" @ChooseProduct="ChooseProduct" />
  </section>
</template>

<route>
{
  "meta": {
    "label": "編輯優惠活動"
  }
}
</route>

<script>
import { CheckCondition } from '@/utils/offer'
export default {
  name: 'ActivityDetail',
  layout: 'manage',
  components: {
    MediaStore: () => import('@/components/public/Media'),
    MediaMeta: () => import('@/components/public/MediaMeta'),
    Multiselect: () => import('@/plugins/mutiselect')
  },
  data () {
    return {
      Action: 'Edit',
      Single: false,
      Loading: false,
      StatusSwitch: true,
      TargetSearchLoading: false,
      ProductListModel: false,
      ProductListModelComponent: null,
      ChooseProductType: null,
      ChooseConditionIndex: null,
      PageList: [
        { Name: this.$t('Offer.AllUsagePage'), Slug: 'ALL' },
        { Name: this.$t('Offer.OfficialWeb'), Slug: 'OFFICIAL' }
      ],
      NoItemsView: {
        noResults: this.$t('Product/List.NoResults'),
        noItems: this.$t('Product/List.NoItems')
      },
      ShopSetting: {},
      MemberFeatures: [],
      TargetGroupList: [],
      MemberLevelList: [],
      PaymentList: [],
      PaymentListOptions: [],
      LogisticsList: [],
      Data: {
        Taxonomy: 'Activity',
        Name: '',
        Description: '',
        Slug: '',
        Event: {
          Type: ''
        },
        Offer: {
          Target: 'NA',
          TargetItem: [],
          DiscountType: 'Price',
          FreeLogisticsFee: false,
          FreeServiceFee: false
        },
        Condition: [
          {
            Value: '1',
            Quantity: '',
            FullAmount: '',
            ItemData: {
              _v: 0
            }
          }
        ],
        TargetGroup: {
          Type: 'ALL',
          List: []
        },
        UsagePage: [
          {
            Name: this.$t('Offer.AllUsagePage'),
            Slug: "ALL"
          }
        ],
        Variable: {
          // AvailableDuplicateOtherOffer: false,
          AvailableUsage: 0,
          AvailableUserRepeatUsage: 1,
          UsageLogistics: []
        },
        Status: 'Y',
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-DD 00:00:00')).unix() * 1000,
        EndTime: this.$dayjs(this.$dayjs().add(1, 'month').format('YYYY-MM-DD 23:59:59')).unix() * 1000
      },
      ChooseImageType: '',
      ChooseImageIndex: false,
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
      MediaMetaConfig: {
        Display: false,
        ImageMeta: {
          URL: ''
        }
      },
      Validation: {}
    }
  },
  computed: {
    TargetItemField () {
      return [
        { key: 'Cover', label: this.$t('Product/List.Cover') },
        { key: 'Name', label: this.$t('Product/List.Name') },
        { key: 'SeriesNum', label: this.$t('Product/List.SeriesNum') },
        { key: 'Action', label: '' }
      ]
    },
    TargetOptions () {
      return Object.keys(this.$t('Offer.TargetOptions')).map(value => {
        return {
          value,
          label: this.$t('Offer.TargetOptions')[value]
        }
      })
    },
    ActivityTypeOptions () {
      return Object.keys(this.$t('Offer.ActivityTypeOptions')).map(value => {
        return {
          value,
          label: this.$t('Offer.ActivityTypeOptions')[value]
        }
      })
    },
    TargetGroupOptions () {
      const Data = []
      Object.keys(this.$t('Offer.ActivityTargetGroupOptions')).forEach(item => {
        switch (item) {
          case 'MemberLevel':
            if (this.MemberFeatures.includes('MemberLevel')) {
              Data.push(item)
            }
            break
          default:
            Data.push(item)
            break
        }
      })
      return Object.keys(this.$t('Offer.ActivityTargetGroupOptions')).map(value => {
        return {
          value,
          label: this.$t('Offer.ActivityTargetGroupOptions')[value]
        }
      })
    }
  },
  created() {
    this.Action = (this.$route.name === 'activity-detail-add' ? 'Add' : 'Edit')
    this.MemberFeatures = this.$store.state.user.permission.Features?.Organization?.Member || []
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  methods: {
    Init() {
      const Execute = [
        this.GetShopSetting(),
        this.GetSinglePageList(),
        this.GetOptionalSelectList(),
        this.GetMemberLevelList()
      ]
      if (this.Action === 'Edit') {
        Execute.push(
          this.GetData()
        )
      } else {
        this.Data.Slug = this.GenerateSlug()
      }
      return Promise.all(Execute).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GenerateSlug () {
      return Math.random().toString(36).substring(3, 8).toUpperCase()
    },
    Save() {
      this.$set(this.$data, 'Validation', CheckCondition(this.Data.Condition, this.Data.Offer.Target))
      if (Object.keys(this.Validation).length > 0) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Message.Offer/ConditionValid'),
          type: 'warn'
        })
        return
      }
      if (this.Data.Name === '') {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Message.Offer/NameEmpty'),
          type: 'warn'
        })
        return
      }
      if (this.Data.Slug === '') {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Message.Offer/SlugEmpty'),
          type: 'warn'
        })
        return
      }
      let Action
      switch (this.Action) {
        case 'Edit':
          Action = this.Edit()
          break
        case 'Add':
          Action = this.Add()
          break
        default:
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Offer/SaveFail-1'),
            type: 'error'
          })
          return
      }
      this.Loading = true
      Action.then((result) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: result.msg,
          type: 'success'
        })
        if (this.Action === 'Add') {
          this.$router.replace('/offer/activity/detail/' + result.Data._id)
        }
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error') + '[' + err.code  + ']',
          text: this.$t('Message.Offer/SaveFail-2') + err.msg,
          type: 'error'
        })
      })
    },
    Add() {
      if (this.Data.Slug === '') {
        return Promise.reject({
          code: 403,
          msg: this.$t('Message.Offer/SlugEmpty')
        })
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/add',
          Data: {
            ...this.Data,
            StartTime: this.$dayjs(this.Data.StartTime).unix(),
            EndTime: this.$dayjs(this.Data.EndTime).unix(),
            Status: this.StatusSwitch === true ? 'Y' : 'N'
          }
        }
      }).then(({data}) => {
        return {
          ...data,
          msg: this.$t('Message.Offer/AddSuccess')
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Edit() {
      if (this.Data.Slug === '') {
        return Promise.reject({
          code: 403,
          msg: this.$t('Message.Offer/SlugEmpty')
        })
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/edit',
          Data: {
            Taxonomy: 'Activity',
            ID: this.$route.params.id,
            UpdateData: {
              ...this.Data,
              StartTime: this.$dayjs(this.Data.StartTime).unix(),
              EndTime: this.$dayjs(this.Data.EndTime).unix(),
              Status: this.StatusSwitch === true ? 'Y' : 'N'
            }
          }
        }
      }).then(() => {
        return {
          msg: this.$t('Message.Offer/EditSuccess')
        }
      }).catch((err) => {
        throw err
      })
    },
    GetData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/manage/detail',
          Data: {
            Taxonomy: 'Activity',
            ID: this.$route.params.id
          }
        }
      }).then(({data}) => {
        this.Loading = false
        this.Data = data.Data
        this.StatusSwitch = this.Data.Status === 'Y'
        delete this.Data._id
        delete this.Data.__v
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetUserData (inputValue) {
      let Action
      switch (this.Data.TargetGroup.Type) {
        case 'Member':
          Action = this.SearchAccount(inputValue)
          break
      }
      return Action.catch(() => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/Unexpected'),
          type: 'error'
        })
      })
    },
    SearchAccount(Email) {
      this.TargetSearchLoading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/user/list',
        method: 'post',
        data: {
          Email
        }
      }).then(({data}) => {
        this.TargetSearchLoading = false
        this.TargetGroupList = data.list
      }).catch((err) => {
        this.TargetSearchLoading = false
        throw err
      })
    },
    GetMemberLevelList() {
      this.Loading = true
      this.MemberLevelList = [
        {
          ID: 0,
          Name: '一般會員',
          ShopDiscount: 100,
          GoldDiscount: 0,
          FullAmount: 0,
          NextFullAmount: '--',
          Status: true
        }
      ]
      return this.$store.dispatch('InnerRequest', {
        url: '/user/level/list',
        method: 'post'
      }).then(({data}) => {
        this.Loading = false
        const TempList = []
        Object.values(data).forEach((item) => {
          if (item.Status === true) {
            TempList.push(item)
          }
        })
        TempList.sort((a, b) => a.FullAmount - b.FullAmount)
        this.MemberLevelList = this.MemberLevelList.concat(TempList).map((item, index) => {
          return {
            ...item,
            NextFullAmount: index === TempList.length ? 'max' : parseInt(TempList[index].FullAmount) - 1
          }
        })
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        throw err
      })
    },
    ChangeOfferTarget() {
      this.Data.Condition = [
        {
          Value: '0',
          Quantity: '1',
          FullAmount: '0',
          ItemData: {
            _v: 0
          }
        }
      ]
      if (this.Action === 'Edit') {
        this.$swal({
          icon: 'info',
          title: '優惠條件或折扣類型已變更',
          text: '切換優惠生效條件或折扣類型時將重置優惠條件設定，若要放棄此變更請重新整理或回到上一頁。',
          showCancelButton: false,
          confirmButtonColor: '#2eb85c',
          confirmButtonText: this.$t('Global.Confirm')
        })
      }
    },
    GetSinglePageList() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/page/shop/list',
        method: 'get',
        params: {
          page: 1,
          perPage: 60,
          order: 'nto'
        }
      }).then(({data}) => {
        this.Loading = false
        const SinglePage = data.list.map(item => {
          return {
            Name: `${this.$t('Offer.SalePage')}: ${item.Name} (${item.Slug})`,
            Slug: item.Slug,
            Taxonomy: 'SalePage'
          }
        })
        this.PageList = [
          ...this.PageList,
          ...SinglePage
        ]
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetOptionalSelectList() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/systemList',
          Data: {
            Taxonomy: 'OptionalSelect',
            Order: 'nto',
          }
        }
      }).then(({data}) => {
        this.Loading = false
        const SinglePage = data.Data.map(item => {
          return {
            Name: `${this.$t('Offer.Taxonomy.OptionalSelect')}: ${item.Name} (${item.Slug})`,
            Slug: item.Slug,
            Taxonomy: 'OptionalSelect'
          }
        })
        this.PageList = [
          ...this.PageList,
          ...SinglePage
        ]
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetShopSetting () {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/get/ShopSetting',
        method: 'post'
      }).then(({data}) => {
        this.ShopSetting = data
        if (this.ShopSetting.Location === true) {
          Object.keys(this.$t('Logistics.CountryOptions')).forEach(value => {
            this.PageList.push({
              Name: this.$t('Offer.OfficialWeb') + ': ' + this.$t('Logistics.CountryOptions')[value],
              Slug: 'Default-' + value
            })
          })
        }
        return true
      }).catch((err) => {
        throw err
      })
    },
    AddCondition() {
      this.Data.Condition.push({
        Value: 1,
        Quantity: null,
        FullAmount: null,
        ItemData: {
          _v: 0
        }
      })
    },
    RemoveCondition(index) {
      this.Data.Condition.splice(index, 1)
    },
    CheckSelectUsagePage(selectedOption) {
      if (selectedOption.Slug === 'ALL') {
        this.Data.UsagePage.filter(item => item.Slug !== 'ALL').forEach(data => {
          this.Data.UsagePage.splice(this.Data.UsagePage.findIndex(item => item.Slug === data.Slug), 1)
        })
      } else if (this.Data.UsagePage.filter(item => item.Slug === 'ALL').length > 0) {
        this.Data.UsagePage.splice(this.Data.UsagePage.findIndex(item => item.Slug === 'ALL'), 1)
      }
    },
    OpenProductListModel(type, index = null) {
      this.ChooseProductType = type
      this.ChooseConditionIndex = index
      this.ProductListModel = true
      this.Single = type === 'Gift';
      this.ProductListModelComponent = () => import('@/components/product/ProductList')
    },
    ChooseProduct(items) {
      items.filter(item => item.Status === 1).forEach(item => {
        const Data = {
          Cover: item.Cover,
          SeriesNum: item.SeriesNum,
          ID: item._id,
          Name: item.Name
        }
        switch (this.ChooseProductType) {
          case 'Default':
          case 'TargetItem':
          case 'TargetExtraItems':
            if (this.Data.Offer.TargetItem.filter(data => data.SeriesNum === Data.SeriesNum).length > 0) {
              this.$notify({
                group: 'notify',
                title: this.$t('Message.Info'),
                text: this.$t('Message.Offer/ChooseProductFail-2'),
                type: 'warn'
              })
              return false
            }
            this.Data.Offer.TargetItem.push(Data)
            break
          case 'Gift':
            if (typeof this.ChooseConditionIndex === 'number') {
              this.$set(this.Data.Condition[this.ChooseConditionIndex], 'ItemData', {
                ...Data,
                Variable: item.Variable,
                EnableOption: item.EnableOption,
                EnableStock: item.EnableStock,
                Stock: item.Stock,
                Status: item.Status
              })
            }
            break
          case 'SelectGift':
            if (typeof this.ChooseConditionIndex === 'number') {
              if (!this.Data.Condition[this.ChooseConditionIndex].ItemDataArray) {
                this.$set(this.Data.Condition[this.ChooseConditionIndex], 'ItemDataArray', [])
              }
              this.Data.Condition[this.ChooseConditionIndex].ItemDataArray.push({
                ...Data,
                Variable: item.Variable,
                EnableOption: item.EnableOption,
                EnableStock: item.EnableStock,
                Stock: item.Stock,
                Status: item.Status
              })
            }
            break
        }
      })
      this.CloseProductListModel()
    },
    RemoveTargetItems (index) {
      this.Data.Offer.TargetItem.splice(index, 1)
    },
    RemoveConditionItemDataArray (conditionIndex, index) {
      this.Data.Condition[conditionIndex].ItemDataArray.splice(index, 1)
    },
    CloseProductListModel() {
      this.ProductListModel = false
      this.ProductListModelComponent = null
    },
    OpenMediaStore(type = 'Cover', multiple = true, index = false) {
      this.ChooseImageType = type
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = multiple
      if (index !== false) {
        this.ChooseImageIndex = index
      }
    },
    async SetImages (type = 'Cover') {
      if (this.$store.state.user.permission.StaticDomain) {
        this.$set(this.Data, type, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
          this.$set(this.Data, type, URL)
        })
      }
    },
    OpenMediaMeta(type = 'Cover') {
      this.MediaMetaConfig.Display = !this.MediaMetaConfig.Display
      this.ChooseImageType = type
      this.MediaMetaConfig.ImageMeta.URL = this.Data[type]
    },
    SetMeta() {
      this.Data.Cover = this.MediaMetaConfig.ImageMeta.URL
      this.MediaMetaConfig.ImageMeta = {
        URL: ''
      }
      this.ChooseImageType = ''
      this.ChooseImageIndex = false
      this.MediaMetaConfig.Display = false
    },
  }
}
</script>

<style lang="scss">
.popover-old {
  min-width: 225px;
}
</style>
